<template>
<div id="services">
      <div class="container-fluid left">
        <div class="row">
          <div class="col-md-12">
            <h1 class="tittle">УСЛУГИ</h1>
            <div class="menu">
              <div class="elem" @click="active = 0">Услуги<i class="ico" :class="{'active':active == 0}" ></i></div>
<!--               <div class="elem" @click="active = 1">Профилактика<i class="ico" :class="{'active':active == 1}" ></i></div>
              <div class="elem" @click="active = 2">Консультация<i class="ico" :class="{'active':active == 2}" ></i></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="contain" v-if="active == 0">
        <div class="row">
          <div class="col-md-4">
            <div class="cart-box">
              <img src="../assets/img/cleaning1.jpg" height="473" width="429" alt="">
              <div class="desc">Химчистка текстильных, шелковых обоев и кожаной мебели</div>
              <div class="price-box-info">
                  <div class="price">От 10 000 р</div>
                  <a href="https://khimchistka.su/chistka-tekstilnyh-oboev/">Подробнее</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="cart-box">
              <img src="../assets/img/after.jpg" height="473" width="429" alt="">
              <div class="desc">Чистка обоев от пыли и грязи</div>
              <div class="price">От 8000 за комнату</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="cart-box">
              <img src="../assets/img/cleaning3.jpg" height="473" width="429" alt="">
              <div class="desc">Помощь в подборе обоев клиентам и дизайнерам</div>
              <div class="price">6000 р</div>
            </div>
          </div>
          <div class="col-md-4 offset-md-4">
            <div class="cart-box">
              <img src="../assets/img/cleaning5.jpg" height="473" width="429" alt="">
              <div class="desc">Сушка помещений и удаление плесени</div>
              <div class="price-box-info">
                <div class="price">от 8000 р</div>
                <a href="https://khimchistka.su/sushka-pomescheniy/">Подробнее</a>
              </div>
            </div>
          </div>
        </div>
       </div>
<!--        <div class="contain" v-if="active == 1">
        <div class="row ">
          <div class="col-md-4">
            <div class="cart-box">
              <img src="../assets/img/cleaning2.jpg" height="473" width="429" alt="">
              <div class="desc">Чистка обоев от пыли и грязи</div>
              <div class="price">От 8 000 р за комнату</div>
            </div>
          </div>
        </div>
       </div>    -->
<!--        <div class="contain" v-if="active == 2">
        <div class="row ">
          <div class="col-md-4">
            <div class="cart-box">
              <img src="../assets/img/cleaning3.jpg" height="473" width="429" alt="">
              <div class="desc">Консультация в подборе обоев клиентам и дизайнерам</div>
              <div class="price">От 6000 р</div>
            </div>
          </div>
        </div>
       </div>   -->        
</div>
</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'headMain',
  data(){
    return{
      data:[
      ],
      active:0,
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
};
</script>


<style scoped>
.price-box-info {
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.cart-box {

}
.cart-box a{
  color:#fff;
  text-decoration: none;
  font-size: 18px;
  display: block;
}
.left {
      padding-left:15%;
}
.contain {
  width:100%;
  padding:0px 7%;
}
.cart-box {
  padding:20px;
  border-radius:20px;
  border: 2px solid #585858;
  margin-top:10%;
}
.cart-box img {
  width:100%;
  height:auto;
  border-radius:20px;
}
.desc {
  font-size: 21px;
  line-height: 28px;
  margin-top:20px;
  min-height: 84px;
}
.price {
  font-size: 23px;
  line-height: 30px;
}
#services {
  text-align:left;
}
.tittle {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 70px;
  line-height: 85px;

}
.menu {
  display:flex;
  max-width:560px;
}
.elem:first-child {
  margin-left:0px;
}
.elem {
  margin:0 10px;
  margin-right: 30px;
  position: relative;
  cursor:pointer;
}
.elem:hover .ico {
  transform: rotate(180deg);
  transition: all 500ms ease;
}
.active {
  transform: rotate(180deg);
  transition: all 500ms ease;
}
.ico {
  width:20px;
  height: 10px;
  background:url('../assets/img/arrow.svg');
  background-size:contain;
  background-repeat: no-repeat;
  position: absolute;
  display:block;
  right:-25px;
  top:6px;
  transition: all 500ms ease;
}
@media(max-width: 900px){
.tittle{
    font-size: 36px;
  text-align: left;
  line-height: inherit;
}
.left {
  padding-left:30px;
  padding-right:30px;
}
.menu {
  flex-direction: column;
}
.elem {
  margin-left: 0px;
  margin-top:7px;
}
}
</style>