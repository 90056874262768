<template>
<!--   <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <navigation/>
  <router-view/>
</template>

<style>

body {
  margin:0px;
  padding:0px;
  background:#111111;
  color:#fff;
}
.container-fluid {
  margin-left:0px;
  margin-right:0px;
  padding-right: 0px;
  padding-left: 0px;
}
.btn {
  background: #DBFF00;
  color:#111111;
  font-size: 18px;
  line-height: 22px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  padding:15px;
  display:block;
  width:186px;
  transition: all 500ms ease;
}
.btn:hover{
  background: #fff;
  transition: all 500ms ease;
}
#app {
  font-family: 'Montserrat';
  text-align: center;
  margin:0px;
  padding:0px;
  width:100%;
}
nav {
  padding: 30px;
}

nav a {
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>

// @ is an alias to /src
import navigation from '@/components/nav.vue'

// import HelloWorld from '@/components/HelloWorld.vue'
// import HelloWorld from '@/components/HelloWorld.vue'
// import HelloWorld from '@/components/HelloWorld.vue'
// import HelloWorld from '@/components/HelloWorld.vue'
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'HomeView',
  components: {
    navigation
  },
  mounted(){
  const anchors = document.querySelectorAll('a[href*="#"]')
  for (let anchor of anchors) {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()
    
    const blockID = anchor.getAttribute('href').substr(1)
    
    document.getElementById(blockID).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })
}
  }
}
</script>
