<template>
  <div id="about">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-5 col-sm-12 non-padding">
            <div class="cart-box">
              <div class="tittle">О нас</div>
              <div class="tittle-2">Профессиональный подход</div>
              <ul>
                <li>Предварительный платный выезд для оценки стоимости и технологии работы;</li>
                <li>Работы выполняются специально обученным персоналом;</li>
                <li>Каждая работа по сути уникальная.</li>
              </ul>
              <div class="desc">Принимаем заказы на выполнение сушки помещений, удаление 
плесени, химчистку кожаной мебели, удаление неприятных запахов.<br><br> Услуги химчистки 
предоставляем с гарантией качества. <br><br><br>
              Позвоните нам и оставьте заявку прямо сейчас</div>
              <a href="#callus" class="btn">Оставить заявку</a>
            </div>
          </div>          
          <div class="col-md-12 col-lg-5 col-sm-12 non-padding min-height">
            <div class="box">
              <div class="row">
                <div class="col-md-6 offset-md-1">
                  <img src="../assets/img/phone.jpg" alt="">
                  <p class="text">Предварительный выезд для оценки работы</p>
                </div>
                <div class="col-md-5 offset-md-1">
                  <img src="../assets/img/image6.jpg" alt="">
                  <p class="text"> Выполнение работы специально обученным персоналом</p>
                </div>
                <div class="col-md-5">
                  <img src="../assets/img/image5.jpg" alt="">
                  <p class="text">Уникальность выполнения каждого заказа</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>


<style scoped>
#about {
      padding-left:15%;
}
.row {
  margin:0px;
}
.min-height{
  min-width: 450px;
}
.non-padding{
  padding:0px;
}
.relative {
  position: relative;
}
.cart-box {
  padding:40px;
  border: 2px solid #585858;
  border-radius: 20px;
  text-align: left;
  margin-top:10%;
}
.cart-box .tittle {
  font-size: 40px;
  line-height: 49px;
      margin: 20px 0px;
}
.cart-box .tittle-2 {
  font-size: 30px;
  line-height: 37px;
}
.cart-box ul {
  padding-left: 14px;
}
.cart-box ul li {
  font-size: 14px;
  line-height: 17px;
  margin:10px 0px;
}
.cart-box .desc {
  font-size: 14px;
  line-height: 17px;
}
.btn {
  margin-top:21px;
}
img{
  border-radius: 30px;
  width:100%;
  height:auto;
}
#about {
  margin-top:5%;
}
.box {
  background: url('../assets/img/Group.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x:-55px;
}
.box .text {
  font-size: 14px;
  text-transform: uppercase;
}
@media(max-width: 900px){
.cart-box .tittle-2 {
  font-size: 18px;
  line-height: 20px;
}
#about {
  padding-left:5%;
}
.box {
  margin-top: 20px;
}
.min-height {
  min-width: 150px;
}
}
</style>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'headMain',
  data(){
    return{
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
}
</script>

