<template>
  <div id="callus">
  	<div class="container">
  		<div class="row">

  			<div class="col-md-10 offset-md-1">
          <div class="box">
            <div class="ico"></div>
            <div class="box_tittle">СВЯЖИТЕСЬ С НАМИ ПРЯМО СЕЙЧАС И МЫ ОТВЕТИМ НА ВАШИ ВОПРОСЫ</div>
            <div class="box_2">
              <input type="text" v-model='this.phone' class="phone" placeholder="+7 903 743 20 32">
              <div class="btn" @click="sendMeassage()">Отправить</div>
            </div>
          </div>
  			</div>

  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'callus',
  components: {
  },
  data(){
  	return{
      token:'5800135292:AAGRP3lIQ0BABv1QZvP2Zt6AV4Ro_SwEJSo',
      phone:'',
      chat_id:'-1001822427307',
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  sendMeassage(){
    if (this.phone.length < 5) {
      console.log('error')
    } else {
      let send_phone = `Заявка с сайта %0AТелефон ${this.phone}`

    this.axios({
    method:'GET',
    url:`https://api.telegram.org/bot${this.token}/sendMessage?chat_id=${this.chat_id}&parse_mode=html&text=${send_phone}`,

    })
    .then(data => {})
    .catch(function(error){
          console.log(error)         
    }) 
    }
    
  }
  },
  computed:{
  	...mapGetters([
	]),
  }
};
</script>

<style scoped>
#callus{
  margin-top:5%;
}
.box{
  position: relative;
  border: 1px solid #585858;
  border-radius: 74px;
  height:250px;
  padding-left:20%;
  padding-right: 5%;
}
.box_tittle {
  margin-top:50px;
  text-align: left;
  font-size:26px;
}
.box_2 {
  display:flex;
  justify-content: space-between;
  margin-top:40px;
}
.phone {
  font-size:26px;
  background: transparent;
  color:#fff;
  outline:none;
  border:none;
  border-bottom:1px solid #585858;
  padding-left:15px;
  max-width: 250px;
}
.phone::placeholder {
  color:#fff;
  opacity: 0.5;
}
.ico {
  width: 150px;
  height:150px;
  background-image: url('../assets/img/callus.svg');
  background-repeat: no-repeat;
  background-size:74px 74px;
  background-position: center center;
  border-radius:100%;
  border: 1px solid #585858;
  position: absolute;
  left: -2px;
  top: 0px;

}
@media (max-width: 900px) {
.ico {
    width: 55px;
    height: 55px;
    background-size: 34px 34px;
    top: 16px;
    left: 8px;
}
.box_tittle {
  font-size: 18px;
}
.box {
  padding-bottom: 20px;
  height: 300px;
}
.phone {
  font-size: 24px;
  max-width: 210px;
}
.btn {
  padding: 10px;
  margin-top:10px;
}
.box_2 {
  flex-direction: column;
  align-items: flex-start;
}

}
</style>

