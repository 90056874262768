<template>
  <div id="stages">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-4">
  				<div class="box">
  					<img src="../assets/img/stages/stage1.svg" alt="">
  					<div class="desc">Заполните форму обратной связи или отправьте фото с вопросом в whatsapp</div>
  					<div class="desc">+7 903 743 20 32</div>
  				</div>
  			</div>
  			<div class="col-md-4">
  				<div class="box">
  					<img src="../assets/img/stages/stage2.svg" alt="">
  					<div class="desc">Наш специалист оперативно свяжется и рассчитает для вас стоимость услуги.</div>
  				</div>
  			</div>
  			<div class="col-md-4">
  				<div class="box">
  					<img src="../assets/img/stages/stage3.svg" alt="">
  					<div class="desc">Наши сотрудники квалифицированно выполнят очистку по уникальной технологии.</div>
  				</div>
  			</div>
  		</div>
  	</div>
  </div>

</template>


<style scoped>
#stages {
	text-align: left;
	margin-top:10%;
}
.box {
	text-align: center;
    border-radius: 100%;
    box-shadow: 5px 5px 0px 0px #dbff00;
    padding:60px 45px;
    min-height: 308px;
}
.box img {
	width:74px;
	height:auto;
}
.desc {
	margin-top:15px;
}
</style>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  }
}
</script>

