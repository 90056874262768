<template>
  <div id="rewiews">
  	<div class="container">
  		<div class="row">

  			<div class="col-md-8 offset-md-2">
  				<h1 class="tittle">ОТЗЫВЫ</h1>

          <div class="rewiews-box">
            <div class="avatar" style="background:#6ca632;">M</div>
            <div class="tittle"><a href="https://yandex.ru/profile/141731633308?intent=reviews&utm_source=badge&utm_medium=rating&utm_campaign=v1">Marina B.</a></div>
            <div class="stars"><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div></div>
            <div class="description">Обращалась для чистки тканевых обоев и дивана. Нужно было удалить пятна и запах от меток домашних питомцев. Очень понравилось то, что прежде чем приступить к работе меня подробно расспросили о характере пятен, попросили фото и образец ткани для проверки как подействует чистящее средство и не повредит ли оно ткань. Кроме того, хочу отметить пунктуальность, качество работы и индивидуальный подход. Большое спасибо Айку за прекрасную работу и отличный результат.</div>
          </div>

          <div class="rewiews-box">
            <div class="avatar" style="background:#5798d9;">A</div>
            <div class="tittle"><a href="https://yandex.ru/profile/141731633308?intent=reviews&utm_source=badge&utm_medium=rating&utm_campaign=v1">Анна</a></div>
            <div class="stars"><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div></div>
            <div class="description">Айк оказал помощь после потопа в помещении. Было высушено в короткие сроки 110 кв.м. разных напольных покрытий . Хочу отметить пунктуальность , порядочность , готовность идти навстречу клиенту и профессиональное отношение Айка.</div>
          </div>

                    <div class="rewiews-box">
            <div class="avatar" style="background:#f285aa;">R</div>
            <div class="tittle"><a href="https://yandex.ru/profile/141731633308?intent=reviews&utm_source=badge&utm_medium=rating&utm_campaign=v1">R</a></div>
            <div class="stars"><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div><div class="ico-stars"></div></div>
            <div class="description">Благодарю за прекрасную химчистку обоев. Замечательные и очень дорогие текстильные обои за 10 лет накопили много пыли и грязи. За 2 дня специалисты почистили 60 кв.м. Обоев за вполне приемлемую сумму. Отличный результат.</div>
          </div>
          <iframe style="margin-top:5%;" src="https://yandex.ru/sprav/widget/rating-badge/141731633308?type=rating" width="150px" height="50px" frameborder="0"></iframe>
  			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'callus',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  }
};
</script>

<style scoped>
#rewiews{
  margin-top:5%;
}
.tittle {
font-weight: 500;
font-size: 36px;
text-align: left;

}
.rewiews-box {
  position: relative;
  padding-left:50px;
}
.rewiews-box .tittle {
  font-size:20px;
  margin-top: 10px;

}
.rewiews-box .tittle  a{
    color:#fff;
  text-decoration: none;
}
.rewiews-box .description {
  font-size:16px;
  margin-top: 10px;
  text-align: left;
}
.stars {
  display: flex;
  margin:5px 0px;
}
.ico-stars {
  background-image: url('../assets/img/stars.svg');
  height:12px;
  width:12px;
  display:block;
  margin:0 3px;
}
.avatar {
  display: flex;
  width: 40px;
  height:40px;
  position: absolute;
  left: 0px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-size:22px;
}
</style>

