<template>
  <div class="home">
    <headMain/>
    <about/>
    <services/>

    <step/>
    <stages/>
    <info/>
    <wallpaper/>

    <compareslide/>
    <callus/>
    <rewiews/>
    <foot/>
  </div>
</template>

<style scoped>
	.home {
		margin:0px;
	}
</style>

<script>
// @ is an alias to /src
import headMain from '@/components/headMain.vue'
import about from '@/components/about.vue'
import services from '@/components/services.vue'
// import question from '@/components/question.vue'
import step from '@/components/step.vue'
import stages from '@/components/stages.vue'
import info from '@/components/info.vue'


import compareslide from '@/components/compareslide.vue'
import callus from '@/components/callus.vue'
import rewiews from '@/components/rewiews.vue'
import foot from '@/components/foot.vue'

export default {
  name: 'HomeView',
  components: {
    headMain,
    about,
    services,

    step,
    stages,
    info,


    compareslide,
    callus,
    rewiews,
    foot
  }
}
</script>
