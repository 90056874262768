<template>
  <div id="compareslide">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="tittle">ПРИМЕР ДО И ПОСЛЕ</h2>
        </div>
        <div class="col-md-10 offset-md-1">
          <VueCompareImage :handleSize="140" leftImage="../assets/img/image5.jpg" rightImage="../assets/img/image6.jpg" />
        </div>
      </div>
    </div>

  </div>

</template>

<style scoped>
#compareslide{

}
.tittle {
font-weight: 500;
font-size: 36px;
text-align: left;
}
</style>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import VueCompareImage from './vuecompaireImg.vue';
export default {
  name:'',
  components: {
    VueCompareImage,
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  }
};
</script>



