<template>
  <div id="info">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-6">
  				<h2 class="tittle">КАКИЕ ОБОИ ЛУЧШЕ ВЫБРАТЬ ПЕРЕД ПОКУПКОЙ?</h2>
  				<div class="desc">Благодаря многолетнему опыту очистки обоев самых дорогих Мировых брендов мы можем с легкостью многое рассказать о текстиле и шелковых обоев которое включает в себя:</div>
  				<ul>
                <li>Наличие качественного красителя;</li>
                <li>Характеристика структуры ткани с точки зрения не только дизайна и красоты, а так же с точки зрения ухода за ними в будущем;</li>
                <li>Мы расскажем вам все о технике применения обоев - когда нужно клеить, и где не нужно клеить текстильные обои и почему...</li>
                <li>И самое главное - какие обои лучше всего поддаются химчистке, что рано или поздно нужно 
любым обоям.</li>
              </ul>
                <a href="#callus"  class="btn">Оставить заявку</a>
  			</div>
  			<div class="col-md-5 offset-md-1">
  				<img src="../assets/img/image10.jpg" alt="">
  			</div>
  		</div>
  	</div>
  </div>

</template>

<style scoped>
#info {
	text-align: left;
	margin-top:10%;
}
#info img {
	width:100%;
	height:auto;
	border-radius: 20px;
}
.tittle {
font-weight: 500;
font-size: 36px;
}
.desc {
  font-size: 18px;
}
#info ul {
  padding-left: 14px;
}
#info ul li {
  font-size: 14px;
  line-height: 17px;
  margin:10px 0px;
}
.btn {
	margin-top:5%;
	margin-left:0px;
}
@media (max-width:900px){
	.btn {
		margin-bottom: 20px;
	}
}
</style>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  }
}
</script>



