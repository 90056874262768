<template>
  <div id="head">
    <div class="container-fluid">
      <div class="flex">
        <div class="col-md-6">
          <div class="left-box">
            <h1 class="tittle">Химчистка текстильных обоев</h1>
            <div class="description">Единственные в этой сфере</div>
            <div class="description-text">Профессиональная химическая чистка текстильных и шелковых обоев  - это, по сути ручная чистка. <br>Работаем по собственной уникальной технологии. Поэтому предлагаем нашим клиентам услугу химчистки текстильных и шелковых обоев с гарантией качества.</div>
            <a href="#callus" class="btn">Оставить заявку</a>
          </div>
        </div>
        <div class="col-md-6">
          
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
#head {
    padding-left:15%;
}
.left-box {
  width:468px;
  height:auto;
  text-align: left;
}
.container-fluid {
  margin-left:0px;
  margin-right:0px;
  padding-right: 0px;
  padding-left: 0px;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    height:781px;
    background:url('../assets/img/phone2.jpg');
    background-size: 960px 781px;
    background-repeat: no-repeat;
    background-position: right top;
}
.tittle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 85px;
  text-align: left;
  margin-top:33%;
}
.description {
  margin-top:43px;
  font-size:22px;
  text-align: left;
}
.description-text {
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin-top: 48px;
}
.btn {
  margin-top: 39px;
}
@media(max-width: 900px){
  #head {
    padding-left:5%;
  }
  .left-box {
    max-width: 290px;
    width: 100%;
  }
  .tittle {
    font-size: 36px;
    line-height: 53px;
    max-width:90%;
  }
  .description-text {
    font-size:15px;
    max-width:80%;
  }
  .flex {
    background-position: left;
  }
}
</style>



<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'headMain',
  data(){
    return{
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
}
</script>

