<template>
  	<div class="nav d" :class="{'active-jump':this.menuScroll}" id="nav">
  		<div class="container-fluid desctop">
	      	<div class="row">
	      		<div class="col-md-12">
	      			<div class="menu" :class="{'menu-jump':this.menuScroll}">
	      				<a href="#head" class="btn-menu">Главная</a>
	      				<a href="#services" class="btn-menu">Услуги</a>
	      				<a href="#about" class="btn-menu">О нас</a>
	      				<a href="#callus" class="btn-menu">Контакты</a>
	      				<a href="https://www.youtube.com/channel/UCzoJRceMqGizu45UCk7Xbog" class="btn-menu" target="_blank">Ютюб Канал</a>
	      				<a href="https://khimchistka.su/blog/" class="btn-menu" target="_blank">Блог</a>
	      				<a href="tel:+79037432032" class="btn-menu">+7 903 743 20 32</a>
	      			</div>
	      		</div>
	      	</div>
	      </div>
	      <div class="container-fluid mobile">
	      	<div class="row">
	      		<div class="col-md-12">
	      			<a v-if="!this.open" @click="openClose()" class="mobile open burger_ico"></a>
	      			<div class="menu" v-if="this.open">
	      				<a class="mobile open close_ico" @click="openClose()"></a>
	      				<a href="#head" class="btn-menu" @click="openClose()">Главная</a>
	      				<a href="#services" class="btn-menu" @click="openClose()">Услуги</a>
	      				<a href="#about" class="btn-menu" @click="openClose()">О нас</a>
	      				<a href="#callus" class="btn-menu" @click="openClose()">Контакты</a>
	      				<a href="https://www.youtube.com/channel/UCzoJRceMqGizu45UCk7Xbog" class="btn-menu" @click="openClose()" target="_blank">Ютюб канал</a>
	      				<a href="https://khimchistka.su/blog/" class="btn-menu" @click="openClose()" target="_blank">Блог</a>
	      				<a href="tel:+79037432032" class="btn-menu">+7 903 743 20 32</a>
	      				<a href="#head" @click="openClose()"><img src="../assets/img/logo.svg" alt=""></a>
	      			</div>
	      		</div>
	      	</div>
	      </div>
	</div>
</template>


<style scoped>
.nav {
	background:transparent;
	position: relative;
	padding-left:15%;
}
.menu {
	display:flex;
	justify-content: space-around;
	width:800px;
	margin:0 auto;
	position: absolute;
	z-index: 15;
	left:13%;
	top:34px;
}
.btn-menu {
	cursor:pointer;
	color:#fff;
	text-decoration: none;
	font-weight: 200;
	font-size: 18px;
	line-height: 29px;
}
.mobile {
	display:none;
}
.open {
	position: fixed;
	right:20px;
	top:40px;
	z-index: 20;
}
.close_ico {
	background:url('../assets/img/close.svg');
	width:25px;
	height:25px;
	cursor:pointer;
	background-size: contain;
}
.burger_ico {
	background:url('../assets/img/burger.svg');
	width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: contain;
}
.active {
	display:none;
}
.desctop {
	display:block;
}
.menu img {
	max-width: 100px;
}
.active-jump {
	background: rgba(0,0,0, .4);
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.menu-jump {
	display: flex;
    justify-content: space-around;
    width: 800px;
    margin: 0 auto;
    position: fixed;
    z-index: 15;
    left: 0;
    top: 24px;
    right: 0;
}
@media(max-width: 1200px){
	.active-jump {
		background: none;
	    height: auto;
	    position: relative;
	    width: 100%;
	    z-index: 100;
	}
	.mobile {
		display:block;
	}
	.desctop {
		display:none;
	}
	.menu{
	    position: fixed;
	    display: flex;
	    flex-direction: column;
	    right: 0px;
	    text-align: right;
	    width: 320px;
	    left: auto;
	    padding:30px;
	    height:100%;
	    padding-right: 50px;
	    top:0px;
	    background:#111111;
	}	
	.menu a {
		margin-top:20px;
	}
	.menu a:first-child{
		margin-top: 0px;
	}	
}
</style>



<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'headMain',
  data(){
    return{
    	open:false,
    	menuScroll:false,
    }
  },
  mounted(){
  	const anchors = document.querySelectorAll('a[href*="#"]')
	  for (let anchor of anchors) {
	  anchor.addEventListener('click', function (e) {
	    e.preventDefault()
	    
	    const blockID = anchor.getAttribute('href').substr(1)
	    })
	}
	    window.addEventListener('scroll',() => this.newPosition(scrollY))
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
  }),
  openClose(){
  	this.open = !this.open
  },
  newPosition(scroll){
	scroll > 150 ? this.menuScroll = true : this.menuScroll = false
  }
  },
  computed:{
    ...mapGetters([
  ]),
  }
}
</script>

