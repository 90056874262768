<template>
<div id="footer">
     <div class="container mt">
       <div class="row">
         <div class="col-md-3"><a href=""><img src="../assets/img/logo.svg" alt=""></a></div>
         <div class="col-md-3">
          <div class="flex">
            <a href="#about" class="text">О нас</a>
            <a href="#head" class="text">Главная</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="flex">
            <a href="#callus" class="text">Контакты</a>
            <a href="#services" class="text">Услуги</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="flex">
            <a href="https://www.youtube.com/channel/UCzoJRceMqGizu45UCk7Xbog" class="text">Youtube</a>
            <a href="https://khimchistka.su/blog/" class="text">Блог</a>
          </div>
        </div>
       </div>
       <div class="row mt bord ">
        <div class="col-md-12">
         <div class="social mt">
           <a href="https://www.youtube.com/channel/UCzoJRceMqGizu45UCk7Xbog" class="ico"><img src="../assets/img/youtube.svg" alt=""></a>
           <a href="https://www.instagram.com/khimchistka.su/?hl=ru" class="ico"><img src="../assets/img/instagrams.svg" alt=""></a>
         </div>
         </div>
         <div class="col-md-12">
           <div class="text-end">Химчистка и сушка © 2022. Все права защищены.</div>
         </div>
       </div>
     </div>
</div>
</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'headMain',
  data(){
    return{
      data:[
      ]
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
};
</script>


<style scoped>
#footer {
  text-align: left;
  border-top:3px solid #585858;
  margin-top:5%;	
  padding-bottom:5%;
}
#footer img {
  max-width:150px;
  height:auto;
}
.mt {
  margin-top: 5%;
}
.flex {
  display:flex;
  flex-direction: column;
}
.text {
  color:#fff;
  text-decoration: none;
  cursor:pointer;
  margin-top:10px;
}
.bord {
  border-top: 1px solid #DBFF00;
}
.social {
  display:flex;
  align-items: center;
  justify-content: center;
}
.social a {
  margin:0px 10px;
  border-radius: 100px;
  border:1px solid #DBFF00;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social a img {
  width:50%;
}
.text-end {
  color:#fff;
  margin-top:5%;
  text-align: center;
}
</style>