<template>
      <div id="step">
        <div class="container">
          <div class="row">


            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12"><h2 class="tittle">НАШИ РЕКОМЕНДАЦИИ!</h2></div>
                <div class="col-md-12"><div class="desc">Текстильные и шелковые обои имеют очень тонкую и нежную структуру волокон. 
Пытаясь самостоятельно чистить вы, или клиннер  можете легко испортить дорогую красоту, а реставрация обоев невозможна.</div></div>
                <div class="col-md-12"><h2 class="tittle">Особые условия для клининговых компаний</h2></div>
                                <div class="col-md-12"><div class="desc">Предлагаем выгодное сотрудничество и привлекательные проценты для вашей компании. Зарабатывайте вместе с нами. <br><br> Заключаем договоры на долгосрочной основе. Вы можете использовать нашу цену в своих прайс листах. Подробности по телефону.</div></div>
              </div>
            </div>

            <div class="col-md-6">
              <h2 class="tittle">ТИПОВЫЕ МЕСТА ОЧИСТКИ!</h2>
              <div class="box-btn" @click="activeNav = 0">
                <div :class="{'active':activeNav == 0, 'btn-quest':activeNav != 0}">Обойный клей  <i class="ico"></i> </div>
                <div class="request" v-if="activeNav == 0"> Удаляется в случае если обои качественно поклеены.</div>
              </div>
              <div class="box-btn" @click="activeNav = 1">
                <div :class="{'active':activeNav == 1, 'btn-quest':activeNav != 1}">Кофе <i class="ico"></i> </div>
                <div class="request" v-if="activeNav == 1"> Удаляется зависит от структуры ткани.</div>
              </div>
              <div class="box-btn" @click="activeNav = 2">
                <div :class="{'active':activeNav == 2, 'btn-quest':activeNav != 2}">Грязь <i class="ico"></i> </div>
                <div class="request" v-if="activeNav == 2"> Удаляется зависит от того, когда наклеены и делалась ли профилактическая чистка.</div>
              </div>
              <div class="box-btn" @click="activeNav = 3">
                <div :class="{'active':activeNav == 3, 'btn-quest':activeNav != 3}">Фломастер <i class="ico"></i> </div>
                <div class="request" v-if="activeNav == 3"> Удаляется в зависимости от структуры ткани и химического состава фломастера.</div>
              </div>
            </div>

          </div>
        </div>
      </div>
</template>



<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'headMain',
  data(){
    return{
      activeNav:0,
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
};
</script>

<style scoped>
#step {
  margin-top: 5%;
  text-align: left;
}
.tittle {
font-weight: 500;
font-size: 36px;
text-transform: uppercase;
}
.desc {
  font-size: 18px;
}
.btn-quest {
    position: relative;
    border-radius: 30px;
    box-shadow: 1px 1px 0px #dbff00;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0px 20px;
    margin:15px 0px;
    cursor:pointer;
}
.ico {
  width: 15px;
  height: 15px;
  background:url('../assets/img/plus.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display:block;
}
.active {
    margin:15px 0px;
    position: relative;
    border-radius: 30px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0px 20px;
    background:#dbff00;
    color:#111111;
    cursor:pointer;
}
.active .ico {
  width: 15px;
  height: 15px;
  background:url('../assets/img/plus_black.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display:block;
}
.request {
  font-size: 14px;
  line-height: 17px;
  font-weight: lighter;
  padding:0px 10px;
}
@media(max-width:900px){
  .tittle {
    max-width:90%;
    font-size: 33px;
  }
}
</style>